<template>
  <div class="rounded-lg w-full" v-if="isSignedIn()">
    <div class="flex flex-col space-y-4 items-start justify-start w-full py-4 bg-gray-900 rounded-lg">
      <div class="inline-flex items-center justify-end w-full pr-4 rounded-lg">
        <div class="flex space-x-4 items-start justify-end">
          <div class="flex items-start justify-start pr-4 border-r border-gray-600">
            <p class="text-base leading-normal text-center text-white">会員情報</p>
          </div>
          <a :href="logoutUrl()" class="text-base leading-normal text-center text-white">ログアウト</a>
        </div>
      </div>
      <div class="flex flex-col items-start justify-start w-full p-4 border-b border-indigo-100">
        <div class="inline-flex space-x-4 items-center justify-start w-full rounded-lg">
          <img :src="profileImageUrl" class="w-10 h-10 rounded-full" loading="lazy">
          <img :src="iconMiniPremiumPath" class="w-6 h-6" v-if="charged" loading="lazy">
          <span class="w-6 h-6" v-else>&nbsp;</span>
          <p class="text-base font-bold leading-normal text-center text-white">
            {{ nickname }}さん
          </p>
        </div>
      </div>
      <div class="inline-flex items-center justify-between w-full px-4">
        <p class="text-base font-bold leading-normal text-center text-white">資産情報の確認</p>
        <img :src="iconChevronPath" class="transform -rotate-180 w-1.5 h-2.5 rounded-full" loading="lazy">
      </div>
      <div class="inline-flex space-x-2.5 items-center justify-between w-full px-4">
        <p class="text-base font-bold leading-normal text-center text-white">株式マイページ</p>
        <img :src="iconChevronPath" class="transform -rotate-180 w-1.5 h-2.5 rounded-full" loading="lazy">
      </div>
      <div class="inline-flex space-x-2.5 items-center justify-between w-full px-4">
        <p class="text-base font-bold leading-normal text-center text-white">投資信託 お気に入り</p>
        <img :src="iconChevronPath" class="transform -rotate-180 w-1.5 h-2.5 rounded-full" loading="lazy">
      </div>
      <div class="inline-flex space-x-2.5 items-center justify-between w-full px-4">
        <p class="text-base font-bold leading-normal text-center text-white">不動産投資 お気に入り</p>
        <img :src="iconChevronPath" class="transform -rotate-180 w-1.5 h-2.5 rounded-full" loading="lazy">
      </div>
      <div class="inline-flex items-center justify-end w-full px-4">
        <p class="text-base leading-normal text-right text-white">ヘルプ・お問い合わせ</p>
      </div>
    </div>
  </div>

  <div class="flex flex-row space-x-4 items-center justify-end w-full" v-if="!isSignedIn()">
    <div class="flex items-start justify-start pr-4 border-r border-gray-600">
      <a :href="loginUrl()" class="text-base leading-normal text-center text-white">ログイン</a>
    </div>
    <a href="https://id.minkabu.jp/sign_up" class="text-base leading-normal text-center text-white">会員登録</a>
  </div>
</template>

<script>
export default {
  name: "SpLoginStatus",

  props: {
    signedUser: Object,
    iconMiniPremiumPath: String,
    iconNoProfileImagePath: String,
    iconChevronPath: String,
    callbackUrl: String,
  },

  data() {
    return {
      nickname: "",
      profileImageUrl: "",
      charged: false,
    }
  },

  mounted() {
    if (this.signedUser && this.signedUser.nickname) {
      this.nickname = this.signedUser.nickname;
      if (this.signedUser.profile_image_url) {
        this.profileImageUrl = this.signedUser.profile_image_url;
      } else {
        this.profileImageUrl = this.iconNoProfileImagePath;
      }
      this.charged = this.signedUser.charged;
    }
  },

  methods: {
    isSignedIn() {
      return this.nickname.length > 0
    },

    loginUrl() {
      return "https://id.minkabu.jp/login?callback_url=" + this.callbackUrl
    },

    logoutUrl() {
      return "https://id.minkabu.jp/logout?callback_url=" + this.callbackUrl
    },
  }
}
</script>

<style scoped>
</style>
