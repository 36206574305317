<template>
  <div class="w-full h-9 bg-white my-1 relative" id="search-box">
    <input type="text" :value="searchText" @input="search" class="border rounded-lg border-gray-400 text-sm" placeholder="銘柄を検索">
    <img @click="search" :src="iconSearchPath" class="absolute top-3 left-36" loading="lazy">
    <search-stock-result :stocks="stocks" v-if="searchText.length > 0 && isShowResult" id="search-result"></search-stock-result>
  </div>
</template>

<script>
import SearchStockResult from "./SearchStockResult.vue"

export default {
  name: "SearchStock",

  props: {
    iconSearchPath: String,
  },

  data() {
    return {
      searchText: "",
      stocks: [],
      minSearchLength: 1,
      isShowResult: false,
    }
  },

  mounted() {
    document.addEventListener('click', (e) => {
      if(this.isShowResult) {
        if(!e.target.closest('#search-result')) {
          this.searchText = "";
        }
      }
    })
  },

  methods: {
    search: _.debounce(async function(e) {
      if(e && e.target) {
        this.searchText = e.target.value;
      }

      if(this.searchText && (this.searchText.length >= this.minSearchLength)) {
        const path = "/stocks/search.json?keyword=" + this.searchText;
        const response = await fetch(path);

        if(response.ok) {
          const result = await response.json();
          this.stocks = result;
          this.isShowResult = true;
        } else {
          this.stocks = [];
        }
      }
    }, 600),
  }
}
</script>

<style scoped>
</style>
