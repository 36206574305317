import { createApp } from 'vue/dist/vue.esm-bundler';

import SearchStock from '../components/SearchStock.vue'
import SearchStockResult from '../components/SearchStockResult.vue'
import SpLoginStatus from '../components/SpLoginStatus.vue'

const app = createApp({
  data() {
    return {
      toggleMyMenu: false,
      toggleGlobalNavi: true,
      baseScrollY: 0,
      toggleGlobalNaviY: 300,
    }
  },

  computed: {
    stickyNaviClass: function() {
      if(!this.toggleGlobalNavi) {
        return "hidden"
      }
      return ""
    },
  },

  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    onScroll() {
      if(this.toggleGlobalNavi) {
        // Gnaviを消す
        if(window.scrollY > (this.toggleGlobalNaviY + this.baseScrollY)) {
          this.toggleGlobalNavi = false;
          this.baseScrollY = window.scrollY;
          return
        }
        if(this.baseScrollY > window.scrollY) {
          this.baseScrollY = window.scrollY;
        }
      } else {
        if(this.baseScrollY < window.scrollY) {
          this.baseScrollY = window.scrollY;
        }
        // Gnaviを表示
        // 絶対的・相対的に基準値以上になった場合
        if(window.scrollY < this.toggleGlobalNaviY || window.scrollY < (this.baseScrollY - this.toggleGlobalNaviY)) {
          this.toggleGlobalNavi = true;
          this.baseScrollY = window.scrollY;
          this.upScrollY = 0;
          return
        }
      }
    },
  },
})

app.component('SearchStock', SearchStock);
app.component('SearchStockResult', SearchStockResult);
app.component('SpLoginStatus', SpLoginStatus);

app.mount('#global_navi');
