<template>
  <div v-if="stocks.length > 0" class="w-80 p-2 absolute top-20 left-44 z-50 flex flex-col bg-white rounded">
    <div v-for="stock in stocks" :key="stock.symbol" class="bg-white rounded inline-flex space-x-2 items-center justify-start w-full px-2 hover:bg-slate-100" @click="redirect(stock)">
      <div class="flex-none w-20 items-start justify-start py-1 pr-4 border-r border-gray-700">
        <p class="text-base leading-normal text-left text-gray-700">{{ stock.symbol }}</p>
      </div>
      <p class="text-base leading-normal text-left text-gray-700 h-6 overflow-y-hidden">{{ stock.abbr }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchStockResult",

  props: {
    stocks: Array,
  },

  data() {
    return {
    }
  },

  methods: {
    redirect(stock) {
      if(stock.country == 1) {
        // 米国株
        window.location = "/stocks/" + stock.symbol;
      } else if(stock.country == 81) {
        // 日株
        window.location.href = "https://minkabu.jp/stock/" + stock.symbol;
      }
    },
  }
}
</script>

<style scoped>
</style>
